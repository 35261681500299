@import './../../../../scss/theme-bootstrap';

.hero-carousel-formatter {
  .slick-prev,
  .slick-next {
    height: 50px;
    width: 50px;
    color: $color-black;
    &.white-arrows {
      color: $color-white;
      &:hover {
        color: $color-gray;
      }
    }
  }
  .slick-next {
    #{$rdirection}: 4%;
    &:before {
      #{$rdirection}: 0;
    }
  }
  @include breakpoint($medium-down) {
    ul.slick-dots {
      position: absolute;
      top: 0;
      li.slick-active button:before {
        background-color: $color-darker-gray;
        color: $color-darker-gray;
      }
    }
  }
}
